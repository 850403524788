<template>
  <v-container>
    <v-row class="mb-5">
      <h2>Ribose Methylation Reaction - Data Sheet</h2>
    </v-row>

    <v-row>
      <p class="mb-3">
        Analyse the GC-MS data for your group experiment and feel up the table below. Obtain data
        from the other group who run experiment at different temperature and include their data in
        your table.
      </p>
    </v-row>

    <v-row class="mb-4">
      <v-simple-table>
        <thead>
          <tr>
            <td rowspan="2" style="font-weight: bold">Sugar</td>
            <td colspan="6" style="text-align: center; font-weight: bold">Time (min) at 25°C</td>
          </tr>
          <tr>
            <td style="font-weight: bold; text-align: center">10</td>
            <td style="font-weight: bold; text-align: center">20</td>
            <td style="font-weight: bold; text-align: center">30</td>
            <td style="font-weight: bold; text-align: center">40</td>
            <td style="font-weight: bold; text-align: center">50</td>
            <td style="font-weight: bold; text-align: center">60</td>
          </tr>

          <tr>
            <td>Methyl-β,D-ribofuranoside (%)</td>
            <td>
              <calculation-input
                v-model="inputs.row1Time10"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row1Time20"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row1Time30"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row1Time40"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row1Time50"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row1Time60"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Methyl-α,D-ribofuranoside (%)</td>
            <td>
              <calculation-input
                v-model="inputs.row2Time10"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row2Time20"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row2Time30"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row2Time40"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row2Time50"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row2Time60"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Methyl-β,D-ribopyranoside (%)</td>
            <td>
              <calculation-input
                v-model="inputs.row3Time10"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row3Time20"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row3Time30"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row3Time40"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row3Time50"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row3Time60"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Methyl-α,D-ribopyranoside (%)</td>
            <td>
              <calculation-input
                v-model="inputs.row4Time10"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row4Time20"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row4Time30"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row4Time40"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row4Time50"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row4Time60"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td rowspan="2" style="font-weight: bold"></td>
            <td colspan="6" style="text-align: center; font-weight: bold">Time (min) at 60°C</td>
          </tr>
          <tr>
            <td style="font-weight: bold; text-align: center">10</td>
            <td style="font-weight: bold; text-align: center">20</td>
            <td style="font-weight: bold; text-align: center">30</td>
            <td style="font-weight: bold; text-align: center">40</td>
            <td style="font-weight: bold; text-align: center">50</td>
            <td style="font-weight: bold; text-align: center">60</td>
          </tr>

          <tr>
            <td>Methyl-β,D-ribofuranoside (%)</td>
            <td>
              <calculation-input
                v-model="inputs.row5Time10"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row5Time20"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row5Time30"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row5Time40"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row5Time50"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row5Time60"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Methyl-α,D-ribofuranoside (%)</td>
            <td>
              <calculation-input
                v-model="inputs.row6Time10"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row6Time20"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row6Time30"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row6Time40"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row6Time50"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row6Time60"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Methyl-β,D-ribopyranoside (%)</td>
            <td>
              <calculation-input
                v-model="inputs.row7Time10"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row7Time20"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row7Time30"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row7Time40"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row7Time50"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row7Time60"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Methyl-α,D-ribopyranoside (%)</td>
            <td>
              <calculation-input
                v-model="inputs.row8Time10"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row8Time20"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row8Time30"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row8Time40"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row8Time50"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.row8Time60"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-row>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';

export default {
  name: 'uoftRiboseLabReportSheet',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        row1Time10: null,
        row1Time20: null,
        row1Time30: null,
        row1Time40: null,
        row1Time50: null,
        row1Time60: null,

        row2Time10: null,
        row2Time20: null,
        row2Time30: null,
        row2Time40: null,
        row2Time50: null,
        row2Time60: null,

        row3Time10: null,
        row3Time20: null,
        row3Time30: null,
        row3Time40: null,
        row3Time50: null,
        row3Time60: null,

        row4Time10: null,
        row4Time20: null,
        row4Time30: null,
        row4Time40: null,
        row4Time50: null,
        row4Time60: null,

        row5Time10: null,
        row5Time20: null,
        row5Time30: null,
        row5Time40: null,
        row5Time50: null,
        row5Time60: null,

        row6Time10: null,
        row6Time20: null,
        row6Time30: null,
        row6Time40: null,
        row6Time50: null,
        row6Time60: null,

        row7Time10: null,
        row7Time20: null,
        row7Time30: null,
        row7Time40: null,
        row7Time50: null,
        row7Time60: null,

        row8Time10: null,
        row8Time20: null,
        row8Time30: null,
        row8Time40: null,
        row8Time50: null,
        row8Time60: null,
      },
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
